<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :fields="fields"
      :row-data="row_data"
      post-api="/kioskupdate/UpgradeKiosk"
      finish-router="kioskManageVersion"
      :submitAction="submitAction"
      permission="Kiosk_Menu_Version"
    >
      <template #facility="scope">
        <slot-facility
          ref="slot_facility"
          :company-id="scope.rowData.companyID"
          :facility-id="scope.rowData.facilityID"
          :row-data="scope.rowData"
          :is-edit="scope.isEdit"
        />
      </template>

      <template #kiosks="scope">
        <slot-kiosks
          ref="slot_kiosks"
          :row-data="scope.rowData"
          :facility-id="scope.rowData.facilityID"
          :platform="scope.rowData.platform"
        />
      </template>
    </edit-field>

  </page-content>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import PageContent from '@/components/PageContent'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import SlotFacility from './SlotFacility'
import SlotKiosks from './SlotKiosks.vue'
import fun from '@/views/group/fun.js'
// import _ from 'lodash'

export default {
  name: 'Add',
  components: {
    SlotKiosks,
    SlotFacility,
    EditField,
    EditFieldHeader,
    PageContent,
    fields,
  },
  data() {
    return {
      title: common.getMenuName('kioskManageVersion') + ' - ' + common.getI18n('kiosk.upgrade'),
      show_modal_bed: false,
      fields,
      row_data: {
        platform: '',
        packageID: null,
        upgradeRange: '',
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        kioskTokens: [],
      },
    }
  },
  created() {
    reloadFieldData()
  },
  methods: {
    addBed: function () {
      fun.addBed(this)
      this.$refs.model_bed.checkSelectAll()
    },
    changeBed: function (type, option) {
      fun.changeBed(this, type, option)
    },
    addMember: function () {
      fun.addMember(this)
      this.$refs.model_member.checkSelectAll()
    },
    changeMember: function (type, option) {
      fun.changeMember(this, type, option)
    },
    changeBedFacilityId: function (facilityID) {
      this.$refs.model_bed.changeFacilityId(facilityID)
    },
    changeMemberFacilityId: function (facilityID) {
      this.$refs.model_member.changeFacilityId(facilityID)
    },

    submitAction(rowData, postApi, finishRouter) {
      const that = this

      const platform = this.row_data.platform
      const devices = fields.find(f => f.name === 'upgradeRange').options.find(o => o.value === that.row_data.upgradeRange).text
      let title = '', text = ''
      if (that.row_data.upgradeRange === 'all') {
        title = `升級 "${platform}" 平台的 "${devices}"？`
        text = `<span class="text-red font-weight-bold">"${devices}" 指所有院舍下的所有設備</span>`
      } else {
        const facility = this.$refs['slot_facility'].options.find(option => option.value === that.row_data.facilityID).text
        title = `升級 "${platform}" 平台下 "${facility}" 的 "${devices}"？`
      }
      common.showConfirm({
        title,
        html: text,
        confirm_fun: submit,
      })

      function submit() {
        that.$requestWehealth({
          url: postApi,
          method: 'post',
          data: {
            'PackageID': rowData.packageID,
            'IsUpgradeAll': rowData.upgradeRange === 'all',
            'KioskTokens': rowData.kioskTokens,
          },
        }).then((res) => {
          that.$router.push({ name: finishRouter })
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
